import { JBGShopify } from '$services/shopify'

export default defineNuxtPlugin(({ $i18n }) => {
    const runtimeConfig = useRuntimeConfig()
    const shopifyProvider = new JBGShopify(<JBGShopify.InitOptions>{
        ...runtimeConfig.public.shopify,
        locale: $i18n.locale.value,
        countryCode: useCookie('cloudfront-viewer-country')?.value || 'US'
    })

    return {
        provide: {
            shopify: shopifyProvider
        }
    }
})
