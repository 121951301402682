import validate from "/home/runner/work/jbg-web/jbg-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import cookies_45global from "/home/runner/work/jbg-web/jbg-web/src/middleware/cookies.global.ts";
import locale_45global from "/home/runner/work/jbg-web/jbg-web/src/middleware/locale.global.ts";
import manifest_45route_45rule from "/home/runner/work/jbg-web/jbg-web/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  cookies_45global,
  locale_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}