<template>
<div class="faq-section home-section">
    <div class="faq-chimp padded">
        <picture alt="Chimpanzee" loading="lazy">
            <source :srcset="`/images/homepage/chimp.webp`" type="image/webp">
            <source srcset="/images/homepage/chimp.png" type="image/png">
            <NuxtImg src="/images/homepage/chimp.png" alt="Chimpanzee" loading="lazy" />
        </picture>
    </div>
    <div class="faq padded">
        <h1 class="section-small-header">{{ $t('HOME.FAQ') }}</h1>
        <p class="section-title">{{ title }}</p>
        <p v-md="body" class="paragraph" />
        <div class="questions">
            <FAQEntry
                v-for="(entry, i) in entries"
                :key="i"
                :question="entry.question"
                :answer="entry.answer"
            />
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import FAQEntry from '$components/FAQEntry.vue'
import type { JBGWeb } from '$types/JBGWeb'

export default defineComponent({
    components: {
        FAQEntry
    },
    props: {
        title: String,
        body: {
            type: String
        },
        entries: Array<JBGWeb.FAQEntry>
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.faq-section {
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  max-width: 1440px;
  margin: auto;
  @include mq-xsmall {
    flex-direction: column;
  }

  .faq-chimp {
    flex: 1;
    @include mq-small {
        img {
            width: 100%;
            object-fit: cover;
        }
    }
    @include mq-xsmall {
        flex: none;
        overflow: hidden;
        height: 350px;
        display: flex;
        justify-content: center;
        picture, img {
            width: 100%;
            max-width: 250px;
        }
    }
  }
  .faq {
    flex: 2;

    @include mq-xsmall {
        flex: 3;
    }

    .questions {
        display: flex;
        gap: 20px;
        flex-flow: column;
    }
  }
}

</style>
