<template>
<button :class="className" :type="type">
    <NuxtImg
        v-if="!!icon && !iconRight" :alt="icon || ''" :ext="ext"
        :src="imageSrc(icon, ext)"
        :class="iconClass"
        aria-hidden
        focusable="false"
        class="icon"
    />
    <slot />
    <NuxtImg
        v-if="!!icon && iconRight" :alt="icon || ''" :ext="ext"
        :src="imageSrc(icon, ext)"
        aria-hidden
        focusable="false"
        class="icon"
    />
</button>
</template>

<script lang="ts">
import {
    defineComponent, type ButtonHTMLAttributes, type PropType, type HTMLAttributes
} from 'vue'

export default defineComponent({
    props: {
        icon: String,
        ext: String, // file extenstion type
        iconRight: Boolean,
        iconClass: String as PropType<HTMLAttributes['class']>,
        // varieties
        primaryGradient: Boolean,
        primary: Boolean,
        secondary: Boolean,
        outline: Boolean,
        play: Boolean,
        fab: Boolean,
        wide: Boolean,
        transparent: Boolean,
        navMenu: Boolean,
        type: String as PropType<ButtonHTMLAttributes['type']>
    },
    computed: {
        // eslint-disable-next-line complexity
        className() {
            let className = ''

            // Set variety
            switch (true) {
            case this.primary:
                className += 'primary '
                break
            case this.primaryGradient:
                className += 'primary-gradient '
                break
            case this.secondary:
                className += 'secondary '
                break
            case this.outline:
                className += 'outline '
                break
            case this.play:
                className += 'play '
                break
            case this.fab:
                className += 'fab '
                break
            case this.transparent:
                className += 'transparent '
                break
            case this.navMenu:
                className += 'nav-menu '
                break
            default:
                className += 'primary '
            }

            if (this.icon) {
                className += 'with-icon'
                if (this.iconRight) {
                    className += ' icon-right'
                }
            }

            if (this.wide) {
                className += ' wide'
            }

            return className
        }
    },

    methods: {
        imageSrc(icon: string, ext?: string): string {
            if (!ext) ext = '.png'
            return new URL(`/images/icons/platforms/${icon}.${ext}`, import.meta.url).href
        }
    }
})
</script>

<style lang="scss" scoped>
    button {
        border: 1px solid #B2BBFF;
        cursor: pointer;
    }

    .icon {
        // all our svg icons are white right now;
        // make them black on buttons
        filter: invert(1);

        height: 18px;
        width: 18px;
    }

</style>
