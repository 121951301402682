<!-- eslint-disable @typescript-eslint/no-unsafe-argument -->
<template>
<header
    class="jbg-web navigation-bar" :class="navBarClasses"
>
    <nav
        class="nav-wrapper" :class="{
            mobile: useMobileNav,
            'no-gradient': $route.path.startsWith('/user') || $route.path.startsWith('/login')
        }"
    >
        <!-- Mobile friendly nav -->
        <template v-if="useMobileNav">
            <div class="mobile-icon-container main-nav">
                <NuxtLink class="logo-link  mobile" :to="localeRoute('/')">
                    <NuxtImg class="logo" src="/images/logo.svg" :alt="$t('NAVBAR.LOGO')" />
                </NuxtLink>
                <NavBarTray
                    ref="mainNav" menu-type="mainNav" icon-only
                    :options="mobileNavOptions"
                    :text="$t('NAVBAR.MAIN_MENU')"
                    :icon="menuIcons.menu"
                    :show-signin-button="showSignInBtn"
                    :user-authenticated="isLoggedIn" :current-user="currentUser" v-on:menu-select="dropdownMenuSelect"
                    v-on:logout="confirmLogout"
                    v-on:login="navigateToLogin"
                    v-on:open="menuOpen"
                />
            </div>

            <div class="mobile-icon-container icons-right">
                <NavBarDropdown
                    ref="language" menu-type="language" icon-only
                    duo-tone-icon
                    align-right :options="languageOptions"
                    :icon="menuIcons.globe" :text="$t('NAVBAR.LANGUAGE_MENU')"
                    v-on:menu-select="langMenuSelect"
                    v-on:open="menuOpen"
                />
                <Cart
                    class="icon-nav-link shopping-cart" menu-type="cart" icon-only
                    duo-tone-icon align-right
                    shopping-cart :icon="menuIcons.shoppingCart" :cart-counter-number="cartCounterNumber"
                />
                <div v-if="showSignInBtn">
                    <div
                        v-if="!isLoggedIn" tabIndex="0" role="button"
                        :aria="$t('NAVBAR.SIGN_IN')"
                        class="sign-in-icon" v-on:click="navigateToLogin" v-on:keydown.enter="navigateToLogin"
                    >
                        <span class="fa-duotone fa-user icon-nav-link user" />
                    </div>
                    <div v-else class="icon-nav-link">
                        <NavBarDropdown
                            menu-type="user" align-right :options="userMenuOptions"
                            :show-signin-button="showSignInBtn" :user-authenticated="isLoggedIn"
                            v-on:menu-select="userMenuSelect"
                        />
                    </div>
                </div>
            </div>
        </template>

        <!-- Desktop friendly nav -->
        <template v-if="!useMobileNav">
            <div class="main-nav-container">
                <NuxtLink class="logo-link" :to="localeRoute('/')">
                    <NuxtImg class="logo" src="/images/logo.svg" :alt="$t('NAVBAR.LOGO')" />
                </NuxtLink>
                <NavBarDropdown
                    ref="games"
                    class="nav-link dropdown paragraph games"
                    :class="{ active: $route.path.startsWith('/games') }"
                    align-left
                    menu-type="generic"
                    :options="gameOptions"
                    :text="$t('NAVBAR.GAMES')"
                    v-on:menu-select="dropdownMenuSelect"
                    v-on:open="menuOpen"
                />
                <NuxtLink class="nav-link paragraph how-to-play" :to="localeRoute('/how-to-play')">
                    {{ $t('NAVBAR.HOW_TO_PLAY') }}
                </NuxtLink>
                <div class="item-with-indicator">
                    <NavBarDropdown
                        ref="company" class="nav-link dropdown paragraph company" :class="{
                            active: $route.path === '/company' ||
                                $route.path === '/blog' ||
                                $route.path === '/presskit'
                        }"
                        align-left :options="companyOptions" :text="$t('NAVBAR.COMPANY')"
                        menu-type="generic"
                        v-on:menu-select="dropdownMenuSelect" v-on:open="menuOpen"
                    />
                </div>
                <NuxtLink class="nav-link paragraph support" :to="localeRoute('/support')">
                    {{ $t('NAVBAR.SUPPORT') }}
                </NuxtLink>
                <div class="item-with-indicator">
                    <NavBarDropdown
                        ref="shop" class="nav-link dropdown paragraph shop"
                        :class="{ active: $route.path.startsWith('/shop') }" align-left :options="shopOptions"
                        :text="$t('NAVBAR.SHOP')" menu-type="generic" v-on:menu-select="shopMenuSelect"
                        v-on:open="menuOpen"
                    />
                </div>
            </div>

            <div class="right-nav-container">
                <NavBarDropdown
                    ref="language"
                    class="icon-nav-link language" menu-type="language" icon-only
                    :text="$t('NAVBAR.LANGUAGE_MENU')"
                    duo-tone-icon
                    align-right :options="languageOptions" :icon="menuIcons.globe"
                    :user-authenticated="isLoggedIn"
                    v-on:menu-select="langMenuSelect"
                    v-on:open="menuOpen"
                />

                <Cart
                    class="icon-nav-link shopping-cart" menu-type="cart" icon-only
                    duo-tone-icon align-right
                    shopping-cart :icon="menuIcons.shoppingCart" :cart-counter-number="cartCounterNumber"
                />

                <div v-if="showSignInBtn">
                    <div
                        v-if="!isLoggedIn" tabIndex="0" role="button"
                        class="sign-in-icon"
                        v-on:click="navigateToLogin"
                        v-on:keydown.enter="navigateToLogin"
                    >
                        <span class="fa-duotone fa-user icon-nav-link user" />
                    </div>
                    <div v-else class="icon-nav-link">
                        <NavBarDropdown
                            ref="user"
                            menu-type="user" align-right :options="userMenuOptions"
                            :text="$t('USER_MENU.ACCOUNT')"
                            :show-signin-button="showSignInBtn" :user-authenticated="isLoggedIn"
                            v-on:menu-select="userMenuSelect"
                            v-on:open="menuOpen"
                        />
                    </div>
                </div>
            </div>
        </template>
    </nav>
</header>
</template>

<script lang="ts">
import { type Component } from 'vue'
import { isNavigationFailure } from 'vue-router'
import { I18n } from '$services/i18n'
import { useCurrentUser } from '$services/user'
import NavBarDropdown from '$components/navigation/NavBarDropdown.vue'
import NavBarTray from '$components/navigation/NavBarTray.vue'
import Cart from '$components/shopping/Cart.vue'
import InfoModal from '$components/InfoModal.vue'

export interface MenuOption {
    text: string
    value: string
}

export interface NavMenuOption extends MenuOption {
    icon?: string
    secondaryMenuOptions?: MenuOption[]
}

export interface MenuItem {
    href: string
    name: string
    svg?: Component
    selected: boolean
}

const iconMap = {
    arrowLeft: 'fa-arrow-left',
    caretDown: 'fa-caret-down',
    globe: 'fa-globe',
    menu: 'fa-regular fa-bars',
    shoppingCart: 'fa-shopping-cart',
    userProfile: 'fa-user',
    xMark: 'fa-xmark'
}

export type menuType = 'cart' | 'language' | 'mainNav' | 'user' | 'generic'

export default defineNuxtComponent({
    components: {
        NavBarDropdown,
        NavBarTray,
        Cart
    },

    data() {
        const currentUser = useCurrentUser()

        return {
            menuIcons: iconMap,
            useMobileNav: false,
            cartCounterNumber: 0,
            currentUser
        }
    },

    computed: {
        mobileNavOptions(): NavMenuOption[] {
            return [
                {
                    value: 'home', text: this.$t('NAVBAR.HOME')
                },
                {
                    value: 'games',
                    text: this.$t('NAVBAR.GAMES'),
                    secondaryMenuOptions: [
                        { value: 'games-packs', text: this.$t('NAVBAR.PACKS') },
                        { value: 'games', text: this.$t('NAVBAR.GAMES') },
                        { value: 'sale', text: this.$t('NAVBAR.ON_SALE') }
                    ]
                },
                {
                    value: 'how-to-play', text: this.$t('NAVBAR.HOW_TO_PLAY')
                },
                {
                    value: 'company',
                    text: this.$t('NAVBAR.COMPANY'),
                    secondaryMenuOptions: [
                        { value: 'blog', text: this.$t('COMPANY.BLOG') },
                        { value: 'jobs', text: this.$t('COMPANY.JOBS') },
                        { value: 'presskit', text: this.$t('COMPANY.PRESSKIT') }
                    ]
                },
                {
                    value: 'support', text: this.$t('NAVBAR.SUPPORT')
                },
                {
                    value: 'shop',
                    text: this.$t('NAVBAR.SHOP'),
                    secondaryMenuOptions: [
                        { value: 'Games', text: this.$t('NAVBAR.GAMES') },
                        { value: 'Merch', text: this.$t('SHOP.MERCH') },
                        { value: 'Giftcards', text: this.$t('SHOP.GIFT_CARDS') }
                    ]
                }
            ]
        },

        companyOptions(): NavMenuOption[] {
            return [
                {
                    value: 'blog', text: this.$t('COMPANY.BLOG')
                },
                {
                    value: 'jobs', text: this.$t('COMPANY.JOBS')
                },
                {
                    value: 'presskit', text: this.$t('COMPANY.PRESSKIT')
                }
            ]
        },

        gameOptions(): NavMenuOption[] {
            return [
                {
                    value: 'games-packs', text: this.$t('NAVBAR.PACKS')
                },
                {
                    value: 'games', text: this.$t('NAVBAR.GAMES')
                },
                {
                    value: 'sale', text: this.$t('NAVBAR.ON_SALE')
                }
            ]
        },

        shopOptions() : NavMenuOption[] {
            return [
                {
                    value: 'Games', text: this.$t('NAVBAR.GAMES')
                },
                {
                    value: 'Merch', text: this.$t('SHOP.MERCH')
                },
                {
                    value: 'Giftcards', text: this.$t('SHOP.GIFT_CARDS')
                }
            ]
        },

        languageOptions(): NavMenuOption[] {
            return [
                {
                    value: 'en', text: this.$t('LANGUAGE_MENU.EN')
                },
                {
                    value: 'fr', text: this.$t('LANGUAGE_MENU.FR')
                },
                {
                    value: 'it', text: this.$t('LANGUAGE_MENU.IT')
                },
                {
                    value: 'de', text: this.$t('LANGUAGE_MENU.DE')
                },
                {
                    value: 'es', text: this.$t('LANGUAGE_MENU.ES')
                },
                {
                    value: 'es-XL' || 'es-419', text: this.$t('LANGUAGE_MENU.ES-XL')
                },
                {
                    value: 'pt-BR', text: this.$t('LANGUAGE_MENU.PT-BR')
                }
            ]
        },

        userMenuOptions(): NavMenuOption[] {
            return [
                {
                    value: `user-profile___${this.$i18n.locale}`, text: this.$t('USER_MENU.PROFILE'), icon: 'fa-user'
                },
                {
                    value: 'logout', text: this.$t('USER_MENU.LOGOUT'), icon: 'fa-arrow-right-from-bracket'
                }
            ]
        },

        isLoggedIn() {
            return this.currentUser.isLoggedIn()
        },

        defaultProfileImgSrc() {
            return '/images/play/mayo.svg'
        },

        navBarClasses() {
            return {
                'no-gradient': this.$route.path.startsWith('/user') || this.$route.path.startsWith('/login'),
                'bottom-border': this.$route.path.startsWith('/shop')
                    || this.$route.name === 'company'
                    || this.$route.name === 'blog'
                    || this.$route.name === 'presskit'
            }
        },

        shopLocale() {
            if (this.$i18n.locale === 'en') {
                return ''
            }
            if (this.$i18n.locale === 'es-XL') {
                return 'es'
            }
            return (this.$i18n.locale)
        },


        showSignInBtn() {
            return this.$route?.name?.toString().startsWith('play')
                || this.$route?.path?.startsWith('/user')
        }
    },

    mounted() {
        this.onScreenResize()

        this.useMobileNav = window.innerWidth < 961
        const lang = this.languageOptions.find((lang) => lang.value === this.$i18n.locale)
        if (lang) {
            lang.icon = 'fa-circle-check'
        }
    },

    methods: {
        confirmLogout() {
            void this.$showModal(
                InfoModal as Component,
                {
                    options: {
                        header: this.$t('LOGOUT_MODAL.HEADER'),
                        text: this.$t('LOGOUT_MODAL.BODY_TEXT'),
                        buttonText: this.$t('LOGOUT_MODAL.BUTTON_TEXT'),
                        value: 'logout'
                    }
                }
            )
        },

        faOptionIcon(option: NavMenuOption): string {
            if (option.icon) {
                return `fa-solid ${option.icon}`
            }

            return ''
        },

        langCheck(value: I18n.Locale) {
            this.languageOptions.forEach((option) => {
                if (option.value === value) {
                    option.icon = 'fa-circle-check'
                } else {
                    option.icon = ''
                }
            })
        },

        langMenuSelect(value: I18n.Locale) {
            this.langCheck(value)

            void this.$i18n.setLocale(value)
            this.$i18n.locale = value

            return value
        },

        menuOpen() {
            // when a dropdown menu opens, we want to  close all existing menus
            if (this.$refs.user) {
                (this.$refs.user as typeof NavBarDropdown).close()
            }
            if (this.$refs.language) {
                (this.$refs.language as typeof NavBarDropdown).close()
            }
            if (this.$refs.mainNav) {
                (this.$refs.mainNav as typeof NavBarTray).close()
            }
        },

        dropdownMenuSelect(value: string) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            void this.$router.push(this.localeRoute({ name: value }))
        },

        shopMenuSelect(value: string) {
            let route
            switch (value) {
            case 'Games':
                route = { name: 'shop-tab', params: { tab: 'games' } }
                break
            case 'Merch':
                route = { name: 'shop-tab', params: { tab: 'merch' } }
                break
            case 'Giftcards':
                route = { name: 'shop-tab', params: { tab: 'giftcards' } }
                break
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            this.$router.push(this.localeRoute(route)).catch((failure) => {
                if (isNavigationFailure(failure)) {
                    // send to shop page on submenu route failure
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    void this.$router.push(this.localeRoute({ name: 'shop-tab', params: { tab: 'games' } }))
                }
            })
        },

        mobileMenuSelect(value: string) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            void this.$router.push(this.localeRoute({ name: value }))
        },

        navigateToLogin() {
            sessionStorage.setItem('redirect', window.location.href)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            void this.$router.push(this.localeRoute({
                name: 'login'
            }))
        },

        navigateToProfile() {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            void this.$router.push(this.localeRoute({
                name: 'user-profile'
            }))
        },

        onScreenResize() {
            window.addEventListener('resize', () => {
                this.useMobileNav = window.innerWidth < 961
            })
        },

        userMenuSelect(value: string) {
            if (value === 'logout') {
                this.confirmLogout()
                return
            }

            void this.$router.push({ name: value })
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.logo {
    height: 40px;
    padding: 6px 48px 0px 0px;
    width: auto;

    @include mq-small-and-less {
        height: 32px;
        padding: 0;
    }

    &:hover{
        // primary-200
        filter: brightness(0) saturate(100%) invert(65%) sepia(61%) saturate(1038%) hue-rotate(196deg) brightness(111%) contrast(101%);
    }
}

.main-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.right-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

    .user {
        width: 17.5px;
        height: 20px;
        color: var(--neutral-100);

        &:hover {
            color: var(--primary-200);
        }
    }
}

.menu-header {
    background: var(--navvy, linear-gradient(180deg, rgba(0, 0, 0, 0.90) 0%, rgba(20, 23, 31, 0.00) 100%));
    display: flex;
    justify-content: space-around;

    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
}

.navigation-bar {
    position: sticky;
    top: 0;
    width: 100%;
    height: 88px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0.00) 100%);
    z-index: 100;
    // transition: all 0.2s;

    // using a psudoelement for the solid background
    // so it can be faded in and out via opacity
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #14161C 0%, #1A1F26 100%);
        opacity: 0;
        z-index: -1;
        transition: opacity 0.25s;
    }

    &.no-gradient {
        background: var(--surface-500);
    }

    &.bottom-border {
        border-bottom: 2px solid var(--primary-200);
        @include mq-small-and-less {
            border-bottom: none;
        }
    }

    &.scrolled {
        border-bottom: none;

        &::before {
            opacity: 1;
        }

        .nav-link {
            &::before, &::after {
                display:none;
            }
        }
    }
}

.nav-link {
    color: var(--neutral-100);
    font-weight: 600;
    padding: 12px;
    border-radius: 8px;

    &:hover, &.router-link-exact-active {
        color:var(--primary-200);

    }

    .shop {
        width: 28px;
        height: 28px;
        margin-left: 20px;
    }
}

.item-with-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.mobile-icon-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-right: -10px;

    &.main-nav {
        gap: 12px;
    }

    &.icons-right {
        gap: 22px;
        margin-right: 12px;
    }

    .sign-in-icon {
        cursor: pointer;
        color: var(--neutral-100);
        width: 17.5px;
        height: 20px;

        &:hover {
            color: var(--primary-200);
        }
    }
}

.nav-wrapper {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.00) 100%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: auto;
    padding: 16px 80px;

    &.no-gradient {
        background: var(--surface-500);
    }

    @include mq-medium {
        padding: 16px 40px;
    }
}

.sign-in-btn {
    margin-left: 10px;
}

@include mq-small-and-less {
    .logo {
        margin-top: 4px;
    }

    .logo-link {
        text-align: center;
    }

    .main-nav-container {
        width: 33.33%;
        justify-content: right;
    }

    .navigation-bar {
        height: 56px;
    }

    .nav-wrapper {
        padding: 14px 16px;
        align-items: center;

        .profile-icon {
            &:hover {
                cursor: pointer;
            }

            &.mobile {
                border: 8px solid var(--surface-800);
                border-radius: 50%;
                height: 100px;
                width: 100px;
                margin-top: 70px;
            }
        }
    }
}
</style>
