<template>
<div class="footer padded" :class="{hidden: $route.path.startsWith('/user')}">
    <div class="description-socials">
        <div class="paragraph">
            <NuxtImg class="logo" src="/images/logo.svg" alt="Jackbox Games logo" />
        </div>
        <div class="socials">
            <a :href="socialLink('fb', $i18n.locale)" target="_blank">
                <span class="visually-hidden">Facebook</span>
                <NuxtImg
                    class="icon" src="/images/socials/facebook.svg" alt="Facebook"
                    aria-hidden="true"
                    focusable="false"
                />
            </a>
            <a :href="socialLink('insta', $i18n.locale)" target="_blank">
                <span class="visually-hidden">Instagram</span>
                <NuxtImg
                    class="icon" src="/images/socials/instagram.svg" alt="Instagram"
                    aria-hidden="true"
                    focusable="false"
                />
            </a>
            <a href="https://www.reddit.com/r/jackboxgames/" target="_blank">
                <span class="visually-hidden">Reddit</span>
                <NuxtImg
                    class="icon" src="/images/socials/reddit.svg" alt="Reddit"
                    aria-hidden="true"
                    focusable="false"
                />
            </a>
            <a :href="socialLink('tiktok', $i18n.locale)" target="_blank">
                <span class="visually-hidden">TikTok</span>
                <NuxtImg
                    class="icon" src="/images/socials/tiktok.svg" alt="TikTok"
                    aria-hidden="true"
                    focusable="false"
                />
            </a>
            <a :href="socialLink('twitter', $i18n.locale)" target="_blank">
                <span class="visually-hidden">Twitter</span>
                <NuxtImg
                    class="icon" src="/images/socials/twitter.svg" alt="Twitter"
                    aria-hidden="true"
                    focusable="false"
                />
            </a>
            <a href="https://www.youtube.com/jackboxgames" target="_blank">
                <span class="visually-hidden">Youtube</span>
                <NuxtImg
                    class="icon" src="/images/socials/youtube.svg" alt="Youtube"
                    aria-hidden="true"
                    focusable="false"
                />
            </a>
        </div>
    </div>
    <div class="copyright-links">
        <div class="copyright">© Copyright {{ new Date().getFullYear() }} Jackbox Games. All rights reserved.</div>

        <div class="links">
            <NuxtLink class="paragraph" to="/terms-of-service">{{ $t('FOOTER.TERMS_OF_SERVICE') }}</NuxtLink>
            <NuxtLink class="paragraph" to="/privacy-policy">{{ $t('FOOTER.PRIVACY_POLICY') }}</NuxtLink>
        </div>
    </div>
</div>
</template>

<script setup lang="ts">
// eslint-disable-next-line complexity
function socialLink(socialPlatform: string, locale: string): string {
    switch (locale) {
    case 'de':
        switch (socialPlatform) {
        case 'fb':
            return 'https://www.facebook.com/jackboxgamesde'
        case 'insta':
            return 'https://www.instagram.com/jackboxgamesde/'
        case 'tiktok':
            return 'https://www.tiktok.com/@jackboxgamesde'
        case 'twitter':
            return 'https://twitter.com/jackboxgamesde'
        default:
            console.warn(`social platform ${socialPlatform} is unknown for locale ${locale}`)
            return ''
        }
    case 'es':
    case 'es-XL':
    case 'es-419':
        switch (socialPlatform) {
        case 'fb':
            return 'https://www.facebook.com/jackboxgameslatam'
        case 'insta':
            return 'https://www.instagram.com/jackboxgameslatam/'
        case 'tiktok':
            return 'https://www.tiktok.com/@jackboxgameslatam'
        case 'twitter':
            return 'https://twitter.com/jackboxgameses'
        default:
            console.warn(`social platform ${socialPlatform} is unknown for locale ${locale}`)
            return ''
        }
    default:
        switch (socialPlatform) {
        case 'fb':
            return 'https://www.facebook.com/JackboxGames/'
        case 'insta':
            return 'https://www.instagram.com/playjackboxgames/'
        case 'tiktok':
            return 'https://www.tiktok.com/@playjackboxgames'
        case 'twitter':
            return 'https://twitter.com/jackboxgames'
        default:
            console.warn(`social platform ${socialPlatform} is unknown for locale ${locale}`)
            return ''
        }
    }
}
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.footer{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
    padding: 80px 0px;
    text-align: left;
    color: var(--neutral-100);

    &.hidden {
        display: none;
    }

    .logo {
        height: 40px;
    }

    .description-socials {
        border-bottom: 1px solid var(--primary-100);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;

        .socials {
            .icon {
                height: 24px;
                margin-left: 24px;
                width: 24px;
            }
        }
    }

    .copyright-links {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 20px;
        .links {
            a {
                color: var(--neutral-50);
                margin-left: 32px;
                text-decoration: none;
            }
        }
    }

    @include mq-small-and-less {
        padding: 32px 0px;
        text-align: center;

        .logo, .paragraph {
            font-size: 14px;
            margin-bottom: 5px;
        }

        .copyright-links, .description-socials {
            justify-content: center;
            display: block;
        }

        .copyright-links {
            flex-wrap: wrap-reverse;

            .links {
                display: flex;
                justify-content: space-evenly;
                margin-bottom: 21px;
                width: 100%;
                a {
                    font-size: 14px;
                    margin: 0px;
                }
            }

            .copyright {
            margin-bottom: 10px;
                font-size: 12px;
            }
        }
    }
}
</style>
