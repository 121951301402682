<template>
<div class="social-section home-section">
    <div class="padded social-content">
        <div class="title">
            <h1 class="section-title">{{ title }}</h1>
        </div>
        <div class="social-links">
            <div v-md:h1="body" class="social-link-content section-subtitle" />
            <div v-md:b1="body" class="social-link-content" />
            <div v-md:h2="body" class="social-link-content section-subtitle" />
            <div class="social-link-content">
                <div class="socials">
                    <!-- TODO: Ask marketing if we want specific tracking links -->
                    <a href="https://www.facebook.com/JackboxGames/" target="_blank">
                        <span class="visually-hidden">Facebook</span>
                        <NuxtImg
                            class="icon" src="/images/socials/facebook.svg" alt="Facebook"
                            aria-hidden="true"
                            focusable="false"
                        />
                    </a>
                    <a href="https://www.instagram.com/playjackboxgames/" target="_blank">
                        <span class="visually-hidden">Instagram</span>
                        <NuxtImg
                            class="icon" src="/images/socials/instagram.svg" alt="Instagram"
                            aria-hidden="true"
                            focusable="false"
                        />
                    </a>
                    <a href="https://www.reddit.com/r/jackboxgames/" target="_blank">
                        <span class="visually-hidden">Reddit</span>
                        <NuxtImg
                            class="icon" src="/images/socials/reddit.svg" alt="Reddit"
                            aria-hidden="true"
                            focusable="false"
                        />
                    </a>
                    <a href="https://www.tiktok.com/@playjackboxgames" target="_blank">
                        <span class="visually-hidden">TikTok</span>
                        <NuxtImg
                            class="icon" src="/images/socials/tiktok.svg" alt="TikTok"
                            aria-hidden="true"
                            focusable="false"
                        />
                    </a>
                    <a href="https://twitter.com/jackboxgames" target="_blank">
                        <span class="visually-hidden">Twitter</span>
                        <NuxtImg
                            class="icon" src="/images/socials/twitter.svg" alt="Twitter"
                            aria-hidden="true"
                            focusable="false"
                        />
                    </a>
                    <a href="https://www.youtube.com/jackboxgames" target="_blank">
                        <span class="visually-hidden">Youtube</span>
                        <NuxtImg
                            class="icon" src="/images/socials/youtube.svg" alt="Youtube"
                            aria-hidden="true"
                            focusable="false"
                        />
                    </a>
                </div>
            </div>
            <div v-md:h3="body" class="social-link-content section-subtitle" />
            <div v-md:b3="body" class="social-link-content" />
            <div class="social-link-content">
                <EmailSignup
                    subscriber-id="homepage"
                    :data-fields="[{ name: 'language', id: '4242', value: listrakLocale }]"
                    :button-text="$t('FORM.SUBMIT')"
                    button-style="outline"
                />
            </div>
        </div>
    </div>
    <div class="characters">
        <div ref="yellowguy" class="yellow-guy">
            <NuxtImg src="/images/homepage/yellowguy.svg" alt="Yellow character from Fakin' It" />
        </div>
        <div class="friends">
            <ScrollAnimation anchor-name="yellowguy">
                <NuxtImg src="/images/homepage/fakinit-characters.svg" alt="Other characters from Fakin' It" />
            </ScrollAnimation>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { I18n } from '$services/i18n'
import EmailSignup from '$components/forms/EmailSignup.vue'
import ScrollAnimation from '$components/ScrollAnimation.vue'

export default defineNuxtComponent({
    components: {
        EmailSignup,
        ScrollAnimation
    },

    props: {
        title: String,
        body: String
    },

    computed: {
        listrakLocale() {
            return I18n.getLocaleForListrak(this.$i18n.locale)
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.social-section {
    background: radial-gradient(76.37% 50% at 50% 50%, #419544 28.89%, #276829 100%);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 50px;

    @include mq-xsmall {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        padding-bottom: 48px;
    }

    .social-content {
        max-width: 600px;
        justify-self: end;
        grid-column: 1 / span 6;
        @include mq-medium-and-less {
            grid-column: 1 / span 5;
        }
        @include mq-xsmall {
            width: unset;
        }
        .title {
            text-align: left;
            display: inline-block;
        }
    }

    .social-links {
        flex: 1;
        .social-link-content {
            max-width: 450px;
            @include mq-xsmall {
                max-width: unset;
            }
        }

        .socials {
            padding-bottom: 32px;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
        }
    }
}

.characters {
    grid-column: 7 / span 6;
    justify-self: right;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    @include mq-medium-and-less {
        max-width: 500px;
        grid-column: 6 / span 7;
    }
    @include mq-xsmall {
        justify-content: center;
        padding-top: 30px;
        // max-height: 300px;
    }

    .friends {
        grid-column: 2 / span 2;
        overflow: hidden;
    }
    img {
        width: 100%;
        height: auto;
    }

}

:deep(.social-link-content) {
    .email-icon {
        color: var(--neutral-300);
    }

    .input {
        color: var(--neutral-900);
        border-bottom: 1px solid var(--neutral-300);
        background: var(--neutral-50);
    }

    .submit-btn {
        color: unset;
        background-color: unset;
        border: 1px solid var(--neutral-50);
    }
}

</style>
