<template>
<div class="packs-section home-section">
    <div class="section-wrapper">
        <h1 class="section-small-header">{{ $t('HOME.PACKS') }}</h1>
        <p class="section-title">{{ title }}</p>
        <div v-md="body" class="info" />
        <div class="packs">
            <NuxtLink
                v-for="pack, index in packs"
                :key="index"
                :to="localeRoute({
                    name: 'games-packs-packslug',
                    params: {
                        packslug: pack.slug,
                    }
                })"
            >
                <NuxtImg
                    v-if="pack.wordmark?.data?.attributes?.url"
                    :src="pack.wordmark?.data?.attributes?.url + '?format=auto&width=300'"
                    :alt="pack.title || ''"
                    loading="lazy"
                />
            </NuxtLink>
        </div>
        <NuxtLink v-t="'HOME.SHOP_PACKS'" to="/games/packs" class="anchor-button shop outline" />
    </div>
</div>
</template>

<script lang="ts">
import type { JBGWeb } from '$types/JBGWeb'

export default defineNuxtComponent({
    props: {
        title: String,
        body: String
    },

    data() {
        return {
            isError: false,
            isLoading: true,
            packs: <JBGWeb.PackDetail[] | null> null
        }
    },

    async asyncData({ $cms, $i18n }) {
        const data = {
            isError: false,
            isLoading: true,
            packs: <JBGWeb.PackDetail[] | null> null
        }

        try {
            data.packs = await $cms.fetchMany<JBGWeb.PackDetail[]>('pack-details', {
                fields: ['slug', 'title'],
                populate: ['wordmark'],
                sort: 'releaseDate:desc',
                locale: $i18n.locale.value
            })
        } catch (error) {
            console.error('Failed to fetch pack details:', error.message)
            data.isError = true
        } finally {
            data.isLoading = false
        }

        return data
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.packs-section {
    @include background-image-set('/images/homepage/packs_bg.jpg', url(/images/homepage/packs_bg.webp));
    background-repeat: repeat;
    background-size: 80%;
    background-position: center;
    text-align: center;

    .section-wrapper {
        flex-direction: column;
        display: flex;
        align-items: center;
    }
}

.info {
    max-width: 760px;
}

.shop {
    width: 400px;
}

.packs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 50px;

    a {
        display: flex;
        padding: 25px 40px;
        width: 20%;
        max-height: 200px;
        justify-content: center;
        transition: transform 0.1s;

        &:hover {
            transform: scale(1.05);
        }
    }

    img {
        object-fit: contain;
        width: 100%;
        height: auto;
    }
}

@include mq-medium-and-less {
    .packs {
        padding: 40px;

        a {
            padding: 30px;
            width: 33.33%;
        }
    }
}

@include mq-xsmall {
    .packs {
        overflow-x: scroll;
        margin: 0 -15px;
        height: 180px;
        flex-wrap: nowrap;
        justify-content: flex-start;
        padding: 15px 15px;
        -ms-overflow-style: none;
        scrollbar-width: none;

        a {
            flex-shrink: 0;
            padding: 15px 15px;
            width: 38%;
            height: 100%;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .info {
        text-align: left;
    }

    .shop {
        width: 100%;
    }
}
</style>
